  // ---------------------- TESTIMONIALS

  #quotes-sec {
    padding: 50px 0 180px;
    background: #d59f57;
    position: relative;

    @media only screen and (max-width: 1500px) {
      padding: 50px 0 150px;
    }

    @media only screen and (max-width: 990px) {
      padding: 50px 5%;
    }

    &:before {
      @include before(50%, 100%);
      background: url(/i/design/quotes-bg-left.png) no-repeat top left;
      left: 0;
      top: 0;
      mix-blend-mode: luminosity;

      @media only screen and (max-width: 990px) {
        opacity: .5;
      }
    }

    &:after {
      @include before(1280px, 350px);
      background: url(/i/design/gold-sword.png) no-repeat center;
      right: 0;
      bottom: 0;

      @media only screen and (max-width: 990px) {
        display: none;
      }
    }

    .wrapper {
      max-width: 95%;
    }

    h2 {
      margin: 50px 0 -80px 0;
      color: #fff;
      font-size: 45px;
      position: relative;
      z-index: 99;
      text-transform: uppercase;
      font-family: 'Frank Ruhl Libre', serif;

      @media only screen and (max-width: 1800px) {
        font-size: 42px;
      }

      @media only screen and (max-width: 1500px) {
        font-size: 40px;
      }

      @media only screen and (max-width: 1350px) {
        font-size: 36px;
      }

      @media only screen and (max-width: 990px) {
        font-size: 34px;
        text-align: center;
        margin: 0 auto 40px;
        line-height: 1;
      }

      @media only screen and (max-width: 500px) {
        font-size: 30px;
      }

      &:before {
        @include before(619px, 1px);
        background: url(/i/design/stories-grad.png) no-repeat center;
        left: 0;
        bottom: -20px;

        @media only screen and (max-width: 990px) {
          display: none;
        }
      }
    }

    ul#bxslider-quotes {

      .bx-viewport {
        width: 100%;
      }

      li {
        padding: 0;
        background: none;
        text-align: left;
        width: 100%;
        margin: 0 auto;
        color: #FFF;
        left: 0;
        min-height: 510px;

        img {
          position: absolute;
          right: 0;
          top: 0;
          width: 340px;
          height: 510px;
          object-fit: cover;

          @media only screen and (max-width: 990px) {
            @include posreset;
            width: 280px;
            height: 400px;
          }
        }

        p {
          width: calc(100% - 340px - 50px);
          margin: 0 50px 0 0;
          padding: 150px 0 0 0;
          font-size: 22px;
          line-height: 1.2;

          @media only screen and (max-width: 1500px) {
            font-size: 18px;
          }

          @media only screen and (max-width: 990px) {
            width: 100%;
            padding: 0;
            margin: 0 auto 30px;
            text-align: center;
            font-size: 16px;
          }

          span {
            display: block;
            margin: 20px 0 0 0;
            text-transform: uppercase;
            text-decoration: underline;
            font-family: 'Frank Ruhl Libre', serif;
          }
        }
      }
    }

    // BXSLIDER CONTROLS - INDICATORS

    .bx-controls {
      display: block;
      text-align: left;
      margin: -100px 0 0 0;
      position: relative;
      z-index: 99;

      @media only screen and (max-width: 990px) {
        margin: 20px auto 0;
        text-align: center;
      }

      .bx-pager-item {
        @include box(50px, 15px);
        display: inline-block;
        margin: 0 10px 0 0;

        .bx-pager-link {
          @include box(50px, 15px);
          display: inline-block;
          overflow: hidden;
          color: transparent;
          font-size: 0;
          background: #FFF;
        }

        .active {
          background: transparent;
          box-shadow: 0 0 0 2px #fff;
        }
      }
    }
  }