header {
 padding: 40px 0 0 0;

 @media only screen and (max-width: 1800px) {
  padding: 50px 0 0 0;
 }

 @media only screen and (max-width: 1500px) {
  padding: 70px 0 0 0;
 }

 @media only screen and (max-width: 1350px) {
  padding: 90px 0 0 0;
 }

 @media only screen and (max-width: 990px) {
  padding: 110px 0 0 0;
 }
}

header>.wrapper {
 padding: 0;
 position: relative;
 z-index: 60;
 width: 90%;
}

header h1 {
 margin: 0;
 background: none;
}

#logo-index {
 display: inline-block;
 margin: 0;
 padding: 0;
 float: left;

 @media only screen and (max-width: 990px) {
  float: none;
  margin: 0 auto;
 }

 a {

  img#logo {
   display: inline-block;
   margin: 0;

   @media only screen and (max-width: 1800px) {
    width: 400px;
   }

   @media only screen and (max-width: 1500px) {
    width: 380px;
   }

   @media only screen and (max-width: 1350px) {
    width: 350px;
   }

   @media only screen and (max-width: 990px) {
    width: 250px;
   }

   @media only screen and (max-width: 990px) {
    display: block;
    margin: 0 auto 50px;
   }
  }
 }
}

// ----------------------------------- SOCIAL DOCK

#social-dock {
 position: absolute;
 right: 30px;
 top: 150px;
 z-index: 99;

 @media only screen and (max-width: 990px) {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: auto;
 }

 li {
  display: block;
  padding: 0;
  background: none;
  margin: 0 0 10px 0;
  position: relative;

  @media only screen and (max-width: 990px) {
   display: inline-block;
   margin: 0 2px;
   vertical-align: top;
  }

  img {
   display: inline-block;
   margin: 0 auto;
   vertical-align: middle;
  }

  a {
   display: block;
   @include box(100%);
  }

  .tooltip {
   position: absolute;
   right: 0;
   top: 50%;
   transform: translateY(-50%);
   margin: 0;
   padding: 10px 10px 10px 20px;
   border-radius: 5px;
   text-align: right;
   text-transform: uppercase;
   color: #fff;
   font-size: 12px;
   pointer-events: none;
   user-select: none;
   transition: .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
   opacity: 0;
   z-index: -1;
   white-space: nowrap;

   @media only screen and (max-width: 990px) {
    display: none;
   }
  }

  &:hover {
   .tooltip {
    opacity: 1;
    right: 40px;
   }
  }
 }
}

// ----------------------------------- GOOGLE TRANSLATE WIDGET

$translate-size: 40px;

.g-tran,
.translate {
 @include box($translate-size);

 img {
  pointer-events: none;
  user-select: none;
  position: relative;
  top: -$translate-size;
 }
}

#google_translate_element {
 opacity: 0;
}

.translate {
 overflow: hidden;
 z-index: 99;
}

.goog-te-gadget .goog-te-combo {
 padding: 10px;
}

// ----------------------------------- SEARCH FORM

a#FS {
 padding: 0px;
 transition: all .3s ease;
 cursor: pointer;
}

/* Search Style */
#search {
 position: fixed;
 top: 0px;
 left: 0px;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.9);
 transition: all 0.5s ease-in-out;
 transform: translate(0px, -100%) scale(0, 0);
 opacity: 0;
 display: none;
}

#search.open {
 transform: translate(0px, 0px) scale(1, 1);
 opacity: 1;
 z-index: 9999999;
 display: block;
}

#search input[type="search"] {
 position: absolute;
 top: 50%;
 left: 0;
 margin-top: -51px;
 width: 60%;
 margin-left: 20%;
 color: rgb(255, 255, 255);
 background: transparent;
 border-left: 0px solid transparent;
 border-right: 0px solid transparent;
 font-size: 40px;
 font-weight: 300;
 text-align: center;
 outline: none;
 padding: 10px;

 @media only screen and (max-width: 990px) {
  width: 70%;
  max-width: 250px;
  background: #fff;
  border-radius: 40px;
  padding: 20px;
  color: #111;
  font-size: 15px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -50px auto 0;
  outline: none;
  box-shadow: 0 0 0 3px #cccccc;
 }
}

// -----------------------------------//
// SCROLLER
// -----------------------------------//

// <a href="#mainholder" id="scroller"><img src="/i/design/scroll-ic.png" alt="Scroll"></a>

// #scroller {
//  width: 50px;
//  position: absolute;
//  right: 0;
//  left: 0;
//  margin: 0 auto;
//  top: 80%;
//  opacity: 0;
//  animation: bob 1s 2s forwards;
//  z-index: 999;

//  @media only screen and (max-width: 990px) {
//   display: none;
//  }
// }

// @keyframes bob {
//  0% {
//   top: 80%
//  }

//  80% {
//   top: 85%;
//   opacity: 1;
//  }

//  100% {
//   top: 80%;
//   opacity: 1;
//  }
// }