// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_2836 #slider {
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 700px;
    min-height: 700px;
  }

  &:after {
    @include before(100%, 500px);
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(#172748, 1) 0%, rgba(#172748, 0) 100%);
    opacity: .7;
    // background: url(/i/design/gradient-bottom.png) repeat-x bottom left;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 550px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 450px;
    min-height: 450px;
  }

  &:before {
    @include before(100%, 450px);
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(#172748, 1) 0%, rgba(#172748, 0) 100%);
    // background: url(/i/design/gradient.png) repeat-x top left;
  }
}

.backstretch {
  position: relative;

  // &:before {
  //   @include before(100%, 500px);
  //   bottom: 0;
  //   left: 0;
  //   background: linear-gradient(0deg, rgba(#172748, 1) 0%, rgba(#172748, 0) 100%);
  //   // background: url(/i/design/gradient-bottom.png) repeat-x bottom left;
  // }

  img {
    top: 0px !important;
    animation: scale 25s ease forwards infinite;
  }

  @keyframes scale {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.25);
    }
  }
}

ul.head-btns {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 50px;
  width: 615px;
  z-index: 99;

  @media only screen and (max-width: 990px) {
    width: 280px;
  }

  li {
    display: inline-block;
    text-align: center;
    width: 300px;
    max-width: 95%;
    transition: .3s;

    @media only screen and (max-width: 990px) {
      display: block;
      margin: 0 auto 5px;
    }

    a {
      display: block;
      height: 80px;
      line-height: 80px;
      color: #fff;
      background: rgba(#fff, .1);
      text-transform: uppercase;
      font-size: 16px;
      font-family: 'Frank Ruhl Libre', serif;
      font-weight: bold;
    }

    &:nth-of-type(1) {
      margin: 0 10px 0 0;

      @media only screen and (max-width: 990px) {
        display: block;
        margin: 0 auto 5px;
      }
    }

    &:hover {
      box-shadow: 0 0 0 2px #fff;

      a {
        box-shadow: inset 0 -80px 0 0 #0c1729;
      }
    }
  }
}