// -----------------------------------//
// NEWS
// -----------------------------------//

#news-sec {
 padding: 100px 0 150px;
 background: #99191a;
 position: relative;

 @media only screen and (max-width: 1500px) {
  padding: 100px 0;
 }

 @media only screen and (max-width: 990px) {
  padding: 80px 5%;
 }

 &:before {
  @include before(100%, 100%);
  background: url(/i/design/news-bg-left.png) no-repeat top left;
  left: 0;
  top: 0;
  mix-blend-mode: luminosity;

  @media only screen and (max-width: 990px) {
   background-size: cover;
  }
 }

 &:after {
  @include before(100%, 100%);
  background: url(/i/design/news-bg-right.png) no-repeat top right;
  right: 0;
  top: 0;
  mix-blend-mode: luminosity;

  @media only screen and (max-width: 990px) {
   display: none;
  }
 }

 ::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
 }

 ::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
 }

 ::-webkit-scrollbar-thumb {
  background: #0c1729;
  border-radius: 10px;
 }

 ::-webkit-scrollbar-thumb:hover {
  background: #213d6b;
  border-radius: 10px;
 }

 h2 {
  text-align: center;
  margin: 0 auto 50px;
  color: #fff;
  font-size: 45px;
  position: relative;
  z-index: 99;
  text-transform: uppercase;
  font-family: 'Frank Ruhl Libre', serif;

  @media only screen and (max-width: 1800px) {
   font-size: 42px;
  }

  @media only screen and (max-width: 1500px) {
   font-size: 40px;
  }

  @media only screen and (max-width: 1350px) {
   font-size: 38px;
  }

  @media only screen and (max-width: 990px) {
   font-size: 34px;
   line-height: 1.2;
  }

  @media only screen and (max-width: 500px) {
   font-size: 30px;
  }

  a {
   display: inline-block;
   text-decoration: underline;
   color: inherit;

   @media only screen and (max-width: 990px) {
    font-size: 18px;
   }
  }
 }

 .wrapper {
  &:before {
   @include before(1450px, 400px);
   box-shadow: 0 0 0 2px rgba(#fff, .3);
   left: -230px;
   top: 180px;
   margin: 0 auto;

   @media only screen and (max-width: 1350px) {
    display: none;
   }
  }

  &:after {
   @include before(68px, 57px);
   background: url(/i/design/tw-bod.png) no-repeat center;
   right: -150px;
   visibility: visible;
   z-index: 99;
   bottom: 150px;

   @media only screen and (max-width: 1350px) {
    display: none;
   }
  }
 }

 .news-cont,
 .tw-cont {
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 99;
 }

 .news-cont {
  position: relative;
  width: 50%;

  @media only screen and (max-width: 990px) {
   width: 500px;
   max-width: 95%;
   display: block;
   margin: 0 auto;
  }

  ul#bxslider-news {

   .bx-viewport {
    width: 100%;
   }

   li {
    padding: 0;
    background: none;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    color: #FFF;
    font-size: 20px;
    left: 0;
   }
  }

  // BXSLIDER CONTROLS - ARROWS 

  $arrow-size: 31px;

  .bx-prev,
  .bx-next {
   @include box($arrow-size);
   position: absolute;
   color: transparent;
   left: 20px;
   top: 160px;
   transform: scaleX(-1);
   background: url(/i/design/news-arrow.png) no-repeat center;
   z-index: 999;
  }

  .bx-next {
   left: auto;
   right: 20px;
   transform: none;
   background: url(/i/design/news-arrow.png) no-repeat center;
  }
 }

 .tw-cont {
  width: calc(50% - 30px);
  margin: 0 0 0 25px;
  height: 415px;
  overflow-y: scroll;
  box-shadow: 0 0 0 5px rgba(#fff, .3);
  border-radius: 10px;
  background: #fff;

  iframe {
   width: 100%;
  }

  @media only screen and (max-width: 990px) {
   width: 500px;
   max-width: 85%;
   display: block;
   margin: 50px auto 0;
  }
 }
}

//News
#SideHeadingNews {

 ul.SideList {
  text-align: center;

  li {
   display: inline-block;
   padding: 0;
   background: none;
   position: relative;
   transition: .3s;
   text-align: left;
   border-radius: 10px;

   &:after {
    @include before(100%, 100px);
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
   }

   a {
    color: #fff;
   }

   img {
    width: 100%;
    object-fit: cover;

    // use actual height of news-default.jpg here to prevent oversize
    height: 320px;
   }

   p.date {
    position: absolute;
    left: 50px;
    bottom: 50px;
    z-index: 99;
    overflow: hidden;
    font-size: 25px;
    font-family: 'Frank Ruhl Libre', serif;

    span {
     display: inline-block;
    }
   }

   p.title {
    position: absolute;
    left: 50px;
    bottom: 20px;
    font-size: 16px;
    padding: 0 30px 0 0;
    z-index: 99;
    text-align: left;

    // Truncate
    span {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     height: 25px;
    }
   }
  }

  @media only screen and (min-width: 990px) {

   &:hover li:not(:hover) {
    transform: scale(.9);
    opacity: .3;
   }
  }
 }

 .btn-wrap {
  margin: 20px auto 0;

  a {
   display: inline-block;
   text-align: center;
   width: 240px;
   height: 70px;
   line-height: 70px;
   color: #0d182c;
   background: #fff;
   text-transform: uppercase;
   font-size: 18px;
   font-family: 'Frank Ruhl Libre', serif;
   font-weight: 800;

   @media only screen and (max-width: 700px) {
    display: block;
    width: 100%;
    margin: 0 auto 5px;
   }

   &:nth-of-type(1) {
    margin: 0 10px 0 0;

    @media only screen and (max-width: 700px) {
     margin: 0 auto 5px;
    }
   }

   &:hover {
    box-shadow: inset 0 -80px 0 0 #0c1729;
    color: #fff;
   }
  }
 }
}