$nav-link-bg-hov: $h1-color;
$nav-dropdown-hov: darken($nav-link-bg-hov, 5%);

// ----------------------------------- NAVIGATION WRAPPERS

nav#navholder {
	position: absolute;
	top: 40px;
	right: 7%;
	margin: 0;
	@extend .clearfix;
	z-index: 9999;
	width: 990px;
	max-width: 100%;
}

// ----------------------------------- MAIN NAVIGATION

ul.nav {
	display: table;
	width: 100%;
	margin: 0;
	list-style: none;
	text-align: right;

	// Top Level List
	li {
		margin: 0;
		background: none;
		position: relative;
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		padding: 0;
		transition: .3s;

		a {
			height: 50px;
			line-height: 50px;
			display: block;
			text-align: center;
			font-size: 18px;
			padding: 0 10px;
			color: #fff;
			border-radius: inherit;
			position: relative;
			font-weight: 500;

			&:before {
				@include before(0, 3px);
				background: #d9a059;
				left: 0;
				right: 0;
				margin: 0 auto;
				bottom: -2px;
				transition: .3s;
			}

			@media only screen and (max-width: 1800px) {
				font-size: 17px;
				padding: 0 8px;
			}

			@media only screen and (max-width: 1500px) {
				font-size: 16px;
				padding: 0 6px;
			}

			@media only screen and (max-width: 1350px) {
				font-size: 15px;
				padding: 0 5px;
			}
		}
	}

	>li:hover>a,
	>li>a:hover {
		color: #fff;
		text-decoration: none;
		// box-shadow: 0 2px 0 0 #fff;

		&:before {
			width: 80%;
		}
	}

	// 2nd Level List
	li {
		ul {
			position: absolute;
			left: -999em;
			margin: 0;
			padding: 5px 20px 20px 15px;
			background: none;
			opacity: 0;
			visibility: hidden;
			transition: (all 0.3s ease-in-out);
		}

		&:hover ul {
			left: auto;
			margin-left: -20px;
			opacity: 1;
			visibility: visible;
			transition: (all 0.3s ease-in-out);
			z-index: 99;
		}

		&:active ul {
			transition: (opacity 0.1s ease-in-out);
		}
	}

	// 2nd Level List Items
	li li {
		float: none;
		display: block;
		padding: 0;
		height: auto;
		line-height: 35px;

		a {
			background: $nav-link-bg-hov;
			height: auto;
			line-height: 1.1;
			white-space: pre-wrap;
			padding: 15px 12px;
			min-width: 200px;
			float: none;
			top: auto;
			display: block;
			position: static;
			text-align: left;
			text-decoration: none;
			font-size: 14px;
			text-transform: none;
			border-bottom: 1px solid rgba(255, 255, 255, .2);
			border-radius: 0;
			box-shadow: none;
		}

		&:hover,
		&:hover>a,
		a:hover,
		a:focus {
			background: $nav-dropdown-hov;
		}
	}

	// Prepend 'more' arrow to parent list items
	li li a.parent:after {
		margin: -1px 0 0 10px;
		content: url('/i/design/lite/nav-right-arrow-white.png');
		opacity: 1;
		line-height: 1;
	}

	// 3rd Level List
	ul ul {
		z-index: 505;
		padding-top: 0;
	}

	li:hover ul ul {
		left: 100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: (all 0.3s ease-in-out);
		pointer-events: none;
		user-select: none;
		margin: 0 0 0 -15px;
	}

	ul li:hover ul {
		left: 100%;
		top: 0;
		opacity: 1;
		visibility: visible;
		transition: (all 0.3s ease-in-out);
		pointer-events: all;
		user-select: all;
	}
}

ul.nav li:last-of-type ul li:hover ul,
ul.nav li:nth-last-of-type(2) ul li:hover ul,
ul.nav li:nth-last-of-type(3) ul li:hover ul {
	left: -180px;
	width: 200px;
	padding: 0;
}

// ----------------------------------- NAVIGATION DOCKED ON SCROLL

#navholder.sticky.fixed {
	position: fixed;
	animation: move-down 1s ease forwards;
	background: rgba($h1-color, .9);
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	backdrop-filter: blur(5px);
	z-index: 9999;

	.wrapper {
		margin: 0 auto;
	}

	ul.nav {
		text-align: center;
		// padding: 5px 0;
	}

	@keyframes move-down {
		from {
			top: -50px;
			opacity: 0;
		}

		to {
			top: 0;
			opacity: 1;
		}
	}
}