	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer {
		clear: both;
		position: relative;
		z-index: 99;
		margin: 0 auto;
		padding: 80px 0 30px;
		background: #0c1729;
		box-shadow: inset 0px 0px 224px 0px rgba(0, 0, 0, 0.58);
		border-top: 5px solid #fff;

		@media only screen and (max-width: 1500px) {
			padding: 60px 0 20px;
		}

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 40px 0 20px;
			margin: 0 auto;
		}

		.wrapper {
			text-align: left;
			font-size: 16px;
			font-weight: 500;
			width: 90%;
			padding: 0 0 0 10%;

			@media only screen and (max-width: 1800px) {
				padding: 0 0 0 8%;
			}

			@media only screen and (max-width: 1500px) {
				padding: 0 0 0 5%;
			}

			@media only screen and (max-width: 1350px) {
				padding: 0;
				text-align: center;
				width: 100%;
			}

			@media only screen and (max-width: 990px) {
				width: 100%;
				padding: 0 5%;
			}
		}

		p,
		a[href^="mailto:"] {
			margin: 0;
			color: #fff;
			text-align: left;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 auto 10px;
				display: block;
				float: none;
			}

			span {
				display: block;
				font-size: 26px;
				color: #d6a352;
				font-family: 'Frank Ruhl Libre', serif;
				font-weight: bold;
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}

		.address,
		.telephone,
		a[href^="mailto:"],
		.footer-logo {
			display: inline-block;
			vertical-align: top;
			margin: 0 5% 0 0;

			@media only screen and (max-width: 990px) {
				display: block;
				margin: 0 auto 20px;
			}
		}

		.footer-logo {
			margin: -10px 7% 0 0;

			@media only screen and (max-width: 1350px) {
				margin: -10px 30px 0 0;
			}

			@media only screen and (max-width: 990px) {
				width: 104px;
				margin: 0 auto 20px;
			}
		}


		a[href^="mailto:"] {
			display: inline-block;
			position: relative;

			@media only screen and (max-width: 990px) {
				display: block;
				max-width: 295px;
				font-size: 14px;
				margin: 0 auto 50px;
			}

			span {}

			&:before {
				@include before(0, 2px);
				border-radius: 10px;
				background: #d6a352;
				transition: .3s;
				left: 0;
				bottom: -5px;
			}

			&:hover {
				&:before {
					width: 100%;
				}
			}
		}
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.creds {
		float: right;
		margin: 0 10% 0 0;


		@media only screen and (max-width: 990px) {
			float: none;
			margin: 0 auto;
		}
	}

	#copyright,
	#credit {
		font-size: 15px;
		text-align: right;
		margin: 0;
		font-family: 'Frank Ruhl Libre', serif;
		font-weight: normal;

		@media only screen and (max-width: 990px) {
			text-align: center;
			display: block;
			margin: 5px auto;
			font-size: 13px;
		}

		a {
			color: #fff;

			@media only screen and (max-width: 990px) {
				margin: 0;
				display: inline-block;
			}

			&:not(:first-of-type) {
				color: #d6a352;
			}
		}
	}

	#copyright {
		a {
			color: #d6a352;
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 80px;
		right: 50px;
		display: block;
		@include box(45px);
		background: $h1-color;
		box-shadow: 0 0 0 3px #fff, 0 0 0 8px rgba(#fff, .2);
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards {
		width: 100%;
		overflow: hidden;
		background: #fff;
		padding: 20px 0;

		.CMSawards {
			overflow: hidden;
			width: 100%;
			height: 100%;
			margin: 0 auto !important;
			background: none !important;

			ul {
				margin: 0;

				li {
					background: none;
					padding: 0 5px;
					margin: 0 10px;
					display: inline-block;

					img {
						max-height: 70px;
						transform: none !important;
					}
				}
			}
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}