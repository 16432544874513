#strapline-sec {
 padding: 100px 0;
 background-color: #d9a059;
 background-image: url(/i/design/gold-shield.png), url(/i/design/gold-crown.png);
 background-repeat: no-repeat;
 background-position: bottom -50px left 100px, center right 100px;
 position: relative;
 text-align: center;
 border-bottom: 5px solid #fff;

 @media only screen and (max-width: 990px) {
  padding: 80px 5%;
  background: #d9a059 url(/i/design/gold-shield.png) no-repeat center;
 }

 &:before {
  @include before(63px, 87px);
  background: url(/i/design/white-shield.png) no-repeat center;
  left: 0;
  right: 0;
  top: -28px;
  margin: 0 auto;
  z-index: 999;
 }

 p {
  margin: 0 auto;
  text-align: center;
  font-size: 2.3vw;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  font-family: 'Frank Ruhl Libre', serif;

  @media only screen and (max-width: 990px) {
   font-size: 22px;

   br {
    display: none;
   }
  }

  &:before {
   @include before(990px, 1px);
   background: url(/i/design/strap-grad.png) no-repeat center;
   left: 0;
   right: 0;
   margin: 0 auto;
   bottom: -20px;

   @media only screen and (max-width: 990px) {
    display: none;
   }
  }
 }
}

#welcome-sec {
 padding: 130px 0;
 background: #0c1729;
 position: relative;

 @media only screen and (max-width: 1500px) {
  padding: 100px 0;
 }

 @media only screen and (max-width: 990px) {
  padding: 80px 5%;
 }

 &:before {
  @include before(100%, 100%);
  background: url(/i/design/welcome-bg.png) no-repeat top right;
  right: 0;
  top: 0;

  @media only screen and (max-width: 1500px) {
   opacity: .5;
  }

  @media only screen and (max-width: 750px) {
   display: none;
  }
 }

 .wrapper {
  margin: 0 0 0 15%;
  width: 1050px;
  max-width: 95%;

  @media only screen and (max-width: 1800px) {
   margin: 0 0 0 10%;
  }

  @media only screen and (max-width: 1500px) {
   margin: 0 0 0 5%;
  }

  @media only screen and (max-width: 1350px) {
   margin: 0 auto;
  }
 }

 .text-cont,
 .img-cont {
  display: inline-block;
  vertical-align: middle;
 }

 .img-cont {
  width: 285px;
  height: 375px;

  @media only screen and (max-width: 990px) {
   display: block;
   margin: 0 auto 50px;
  }

  img {
   width: 100%;
   height: 100%;
   object-fit: cover;
  }
 }

 .text-cont {
  width: calc(100% - 285px - 50px);
  margin: 0 0 0 45px;
  color: #fff;

  @media only screen and (max-width: 990px) {
   display: block;
   width: 100%;
   margin: 0 auto;
  }

  h2 {
   margin: 0 0 30px 0;
   color: #d9a059;
   font-size: 25px;
   font-family: 'Frank Ruhl Libre', serif;
   font-weight: 600;

   @media only screen and (max-width: 1800px) {
    font-size: 24px;
   }

   @media only screen and (max-width: 1500px) {
    font-size: 23px;
   }

   @media only screen and (max-width: 1350px) {
    font-size: 22px;
   }

   @media only screen and (max-width: 990px) {
    font-size: 20px;
    line-height: 1.2;
   }

   @media only screen and (max-width: 500px) {
    font-size: 18px;
   }

   span {
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 400;

    @media only screen and (max-width: 1800px) {
     font-size: 32px;
    }

    @media only screen and (max-width: 1500px) {
     font-size: 30px;
    }

    @media only screen and (max-width: 1350px) {
     font-size: 28px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 26px;
    }

    @media only screen and (max-width: 500px) {
     font-size: 24px;
    }
   }
  }

  p {
   margin: 0 0 20px 0;

   @media only screen and (max-width: 1350px) {
    font-size: 16px;
   }

   &:first-of-type {
    font-size: 20px;
    font-weight: bold;

    @media only screen and (max-width: 1350px) {
     font-size: 18px;
    }
   }

   &:last-of-type {
    margin: 20px 0 0 0;
    text-align: right;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Frank Ruhl Libre', serif;
    font-size: 20px;

    @media only screen and (max-width: 1350px) {
     font-size: 18px;
    }
   }
  }

  //  .read-more {
  //   display: block;
  //   text-align: center;
  //   width: 300px;
  //   height: 50px;
  //   line-height: 50px;
  //   color: #fff;
  //   background: #eee;

  //   &:hover {}
  //  }
  // }
 }
}








// -----------------------------------//
// EMPTY QUEERS
// -----------------------------------//

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1350px) {}

@media only screen and (max-width: 990px) {}

@media only screen and (max-width: 500px) {}

@media only screen and (max-width: 650px) and (orientation:landscape) {}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
all and (-ms-high-contrast: active),
screen\0,
screen\9 {}

// Edge fixes
@supports (-ms-ime-align:auto) {}