// ----------------------------------- FONTS

@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700;800&display=swap');

// font-family: 'Frank Ruhl Libre', serif;
// font-family: 'Montserrat', sans-serif;

$body-font: 'Montserrat',
sans-serif;
$heading-font: 'Frank Ruhl Libre',
serif;

// ----------------------------------- VARIABLES

// their dumb colours
// #c15827;
// #ce7a20;
// #292558;
// #006241;
// #006398;
// #683165;

$link-color: deepskyblue;

$h1-color: #292558;
$h2-color: #006241;
$h3-bg-color: #006398;
$h4-bg-color: #c15827;

// ----------------------------------- INSIDE PAGES VARIABLES

$dd-bg: #8c1558;
$dd-color: #111;

// ----------------------------------- MEANMENU VARIABLES

$menu-main-bg: #0b1627;
$menu-drop-bg: darken($menu-main-bg, 5%);

// ----------------------------------- SIDELIST HOVER STYLES

$col-right-bg: #0b1627;
$col-right-outline: #fff;
$rp-col-hov: rgba(255, 255, 255, .1);

// background-color: rgba(255, 255, 255, .1);

// ----------------------------------- BLOCKQUOTES

$blockquote-background-color: #fff;
$blockquote-border-color: #c15827;
$blockquote-color: #c15827;
$blockquote-speech-mark-color: #c15827;
$blockquote-font-size: 1em;

// ----------------------------------- MOUSE STYLES 

.follower {
	position: absolute;
	transform: translateX(calc(-50% + 25px)) translateY(calc(-50% + 25px));
	border-radius: 50%;
	pointer-events: none;
	user-select: none;
	transition: transform .3s cubic-bezier(0.68, -0.55, 0.265, 1.55), height .3s cubic-bezier(0.68, -0.55, 0.265, 1.55), width .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	z-index: 9999;
	background: $h1-color;
	box-shadow: 0 0 0 2px transparent;
	// , 2px 2px 8px 2px rgba(0, 0, 0, .1);
	padding: 5px;

	@media screen and (max-width: 1050px) {
		display: none;
	}
}

.follow-hover {
	transform: translateX(calc(-50% + 25px)) translateY(calc(-50% + 25px)) scale(3);
	background: #fff;
	box-shadow: 0 0 0 2px #fff, 2px 2px 8px 2px rgba(0, 0, 0, .1);
	opacity: .1;
}

// ----------------------------------- BODY

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: $body-font;
}

.body_2836 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .3s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

// ----------------------------------- WRAPPER

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
	max-width: 100%;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_2836 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// ----------------------------------- MAIN HOLDER

#mainholder {
	width: 100%;
	margin: 0 auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	background: #fff url(/i/design/gold-crown.png) no-repeat bottom 50px right 50px;

	@media only screen and (max-width: 990px) {
		background: #fff;
	}

	&:before {
		@include before(438px, 623px);
		background: url(/i/design/logo-bg.png) no-repeat center;
		left: 3%;
		top: 130px;
		mix-blend-mode: luminosity;
		opacity: .075;

		@media only screen and (max-width: 990px) {
			background-size: cover;
		}
	}
}

// home page alternate
.body_2836 #mainholder {
	padding: 0;
	margin: 0;
	// position: relative;

	&:before {
		display: none;
	}

	* {
		// transition: .3s;
	}
}

// ----------------------------------- CONTENT COLUMNS

//Content 1
.Content1Column {
	width: 1360px;
	max-width: 95%;
	margin: 0 auto 50px;
	padding: 30px 50px 30px 30px;
	min-height: 350px;
	text-align: left;
	// background: rgba(255, 255, 255, .95);
	@extend .clearfix;
	// border-radius: 20px 10px 30px 10px;
	// outline: 2px solid #fff;
	// outline-offset: 5px;
	// position: relative;
	// z-index: 99;
	// margin: -100px 0 50px 2%;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(98% - 350px);
	margin: 0 0 50px 2%;
	padding: 30px 50px 30px 30px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	// background: rgba(255, 255, 255, .95);
	@extend .clearfix;
	// border-radius: 20px 10px 30px 10px;
	// outline: 2px solid #fff;
	// outline-offset: 5px;
	// position: relative;
	// z-index: 99;
	// margin: -100px 0 50px 2%;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

.Content1Column,
.Content2Column {

	a img,
	img a,
	img,
	p a img {
		border: 0;
		border-bottom: 0;
		text-decoration: none;
		vertical-align: middle;
	}

	h1 {
		font-size: 52px;
		margin: 0 0 20px 0;
		color: $h1-color;
		line-height: 1.2;
		font-weight: bold;

		@media only screen and (max-width: 1800px) {
			font-size: 48px;
		}

		@media only screen and (max-width: 1500px) {
			font-size: 42px;
		}

		@media only screen and (max-width: 1350px) {
			font-size: 38px;
		}

		@media only screen and (max-width: 990px) {
			font-size: 35px;
		}
	}

	h2 {
		font-size: 35px;
		margin: 25px 0 20px 0;
		color: $h2-color;
		line-height: 1.3;

		@media only screen and (max-width: 1800px) {
			font-size: 32px;
		}

		@media only screen and (max-width: 1500px) {
			font-size: 30px;
		}

		@media only screen and (max-width: 1350px) {
			font-size: 28px;
		}

		@media only screen and (max-width: 990px) {
			font-size: 25px;
		}
	}

	h3 {
		font-size: 22px;
		margin: 25px 0 10px 0;
		padding: 5px 10px 3px 10px;
		background: $h3-bg-color;
		color: #fff;
		// border-radius: 8px;

		@media only screen and (max-width: 1800px) {
			font-size: 20px;
		}

		@media only screen and (max-width: 1500px) {
			font-size: 19px;
		}

		@media only screen and (max-width: 1350px) {
			font-size: 18px;
		}

		@media only screen and (max-width: 990px) {
			font-size: 16px;
		}
	}

	h4 {
		@extend h3;
		background-position: 10px center;
		background-repeat: no-repeat;
		padding-left: 30px;
		background-color: $h4-bg-color;
	}

	h4.open {
		background-image: url(/i/design/expanding-faq/minus-white.png);
	}

	h4.closed {
		background-image: url(/i/design/expanding-faq/plus-white.png);
	}

	h5 {
		font-size: 12px;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: $heading-font;
	}

	h6,
	small {
		font-size: 10px;
	}

	h1+h2 {
		margin-top: 0;
		border: 0;
		padding: 0;
	}

	h1+p,
	h2+p,
	h3+p {
		margin-top: 0;
	}

	p {
		margin: 12px 0;
		padding: 0;
	}

	li {
		margin: 2px 0;
		padding: 0 0 0 23px;
		background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
	}

	hr {
		height: 1px;
		border-width: 0;
		color: #f2f2f2;
		background-color: #f2f2f2;
	}
}

// ----------------------------------- COLUMN LEFT & COLUMN RIGHT

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
	// border-radius: 20px 10px 30px 10px;
	background: $col-right-bg;
	outline: 2px solid $col-right-outline;
	outline-offset: -5px;
	overflow: hidden;
	position: relative;

	&:before {
		@include before(100%, 100%);
		background: url(/i/design/news-bg-right.png) no-repeat top right;
		right: 0;
		top: 0;
		mix-blend-mode: luminosity;
		background-size: cover;
		opacity: .5;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}
}

// FOR IP PIMPIN

// .ColumnRight {
// 	width: 250px;
// 	height: 100%;
// 	margin: -70px 0 0 0;
// 	padding: 0;
// 	min-height: 250px;
// 	text-align: left;
// 	display: inline-block;
// 	float: right;
// 	box-sizing: border-box;
// 	position: relative;
// 	z-index: 99;

// 	@media only screen and (max-width: 990px) {
// 		background: #090909;
// 	}

// 	&:before {
// 		@include before(500px, 1000%);
// 		background: #090909 url(/i/design/rp-bg.png) no-repeat top 300px right -100px;
// 		right: -200px;
// 		z-index: 1;

// 		@media only screen and (max-width: 1500px) {
// 			@include before(340px, 1000%);
// 			right: -65px;
// 		}

// 		@media only screen and (max-width: 990px) {
// 			display: none;
// 		}
// 	}
// }

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// ----------------------------------- RELATED PAGES

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 20px 5%;

	h2 {
		display: block;
		text-align: center;
		font-size: 25px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
		font-weight: bold;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			display: block;
			width: 100%;
			text-align: left;

			a.child {
				background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
				padding-left: 40px !important;
			}

			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: $rp-col-hov;
				}
			}
		}
	}
}