$inner-text-size: 50px;

#animated-stats {
  padding: 120px 0;
  background: #fff url(/i/design/silver-crown.png) no-repeat center;
  text-align: center;

  @media only screen and (max-width: 1500px) {
    padding: 80px 0 60px;
  }

  @media only screen and (max-width: 990px) {
    padding: 50px 5% 0;
  }

  .wrapper {
    width: 1200px;
    max-width: 100%;

    @media only screen and (max-width: 1200px) {
      width: 800px;
    }

    @media only screen and (max-width: 990px) {
      width: 100%;
    }
  }

  h2 {
    text-align: center;
    margin: 0 auto 50px;
    color: #0a1626;
    font-size: 55px;
    position: relative;
    z-index: 99;
    text-transform: uppercase;
    font-family: 'Frank Ruhl Libre', serif;
    font-weight: bold;

    @media only screen and (max-width: 1800px) {
      font-size: 52px;
    }

    @media only screen and (max-width: 1500px) {
      font-size: 48px;
    }

    @media only screen and (max-width: 1350px) {
      font-size: 44px;
    }

    @media only screen and (max-width: 1200px) {
      margin: 0 auto -50px;
    }

    @media only screen and (max-width: 990px) {
      font-size: 40px;
      margin: 0 auto -100px;
    }

    @media only screen and (max-width: 500px) {
      font-size: 30px;
    }
  }
}

#performance-group {
  width: 100%;
  font-size: 0; // hides physical integer

  @media only screen and (max-width: 1500px) {
    transform: scale(.9);
  }

  @media only screen and (max-width: 1300px) {
    transform: scale(.7);
  }

  @media only screen and (max-width: 1200px) {
    transform: scale(.8);
  }

  #performance,
  #performance2,
  #performance3,
  #performance4 {
    border-radius: 50%;
    outline: 3px solid #fff;
    outline-offset: -2px;
    box-shadow: 0 0 0 7px #eee;
  }

  text {
    fill: #fff;
    font-size: $inner-text-size;
    font-family: 'Frank Ruhl Libre', serif;
    font-weight: bold;
  }

  .donut-item {
    width: 270px;
    display: inline-block;
    margin: 0 15px;
    position: relative;
    vertical-align: top;

    @media only screen and (max-width: 1200px) {
      margin: 50px;

      &:last-of-type {
        margin-bottom: -50px;
      }
    }

    @media only screen and (max-width: 990px) {
      margin: 0 auto 50px;
      display: block;

      &:last-of-type {
        margin-bottom: -50px;
      }
    }

    // for decimal case

    &:nth-of-type(1),
    &:nth-of-type(3) {
      text {
        fill: none;
      }

      #decimal {
        position: absolute;
        top: 105px;
        display: block;
        width: 100%;
        color: #fff;
        z-index: 999;
        font-size: $inner-text-size;
        font-family: 'Frank Ruhl Libre', serif;
        font-weight: bold;
        // background: red;
      }
    }

    p {
      font-size: 22px;
      color: #0a1626;
      margin: 40px auto 0;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1.2;

      span {
        display: block;
        text-transform: none;
        margin: 10px 0 0 0;
        font-weight: 500;
      }
    }
  }

  #performance {
    background: #292558;

    path.color0 {
      fill: #fff;
    }

    path.color1 {
      fill: #292558;
    }
  }

  #performance2 {
    background: #99282f;

    path.color0 {
      fill: #fff;
    }

    path.color1 {
      fill: #99282f;
    }
  }

  #performance3 {
    background: #d6a352;

    path.color0 {
      fill: #fff;
    }

    path.color1 {
      fill: #d6a352;
    }
  }

  #performance4 {
    background: #c6c6c6;

    path.color0 {
      fill: #fff;
    }

    path.color1 {
      fill: #c6c6c6;
    }
  }
}

// path.color0 {
//   fill: #111;
// }

// path.color1 {
//   fill: #222;
// }